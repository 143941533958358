import React from 'react';

export default function Relationship(props) {
  const handleSelectedRelationships = (relationships) => {
    const firstRel = JSON.parse(props.relationshipsText[0]);
    let occurrences = relationships.reduce(function (occ, item, index) {
      const rel = JSON.parse(props.relationshipsText[index]);
      for (let i = 0; i < rel.CLASSIFICATION.length; i++) {
        const clas = rel.CLASSIFICATION[i];
        occ[clas] = (occ[clas] || 0) + 1;
      }
      return occ;
    }, {});

    const sortedOccurrences = [];
    for (var item in occurrences) {
      sortedOccurrences.push([item, occurrences[item]]);
    }
    sortedOccurrences.sort(function (a, b) {
      return b[1] - a[1];
    });

    return (
      <div className='aligner'>
        <div>
          {areaLeft('GENERAL', firstRel['GENERAL'])}
          {areaLeft('SPECIFIC', firstRel['SPECIFIC'])}
          <div className='relationship-area-left'>
            <label className='relationship-sub-area-left'>CLASSIFICATION</label>
            <label className='relationship-sub-area-right'>
              {sortedOccurrences.map((c, index) => {
                return (
                  <React.Fragment key={c[0]}>
                    {index < 4 && <span className=''>{c[0] + ','}&nbsp;</span>}
                    {index === 4 && <span className=''>{c[0]}</span>}
                    {index > 4 && index === sortedOccurrences.length - 1 && (
                      <span className=''>
                        &nbsp;+{sortedOccurrences.length} more
                      </span>
                    )}
                  </React.Fragment>
                );
              })}
            </label>
          </div>
          {areaLeft('SOURCE DATASET', firstRel['SOURCE_DATASET'])}
          <div className='relationship-area-left'>
            <label className='relationship-sub-area-left'>SIMILARITY</label>
            <label className='relationship-sub-area-right'>
              {Object.keys(firstRel['SIMILARITY']).map((sim, index) => (
                <span
                  className=''
                  style={{ display: 'inline-block' }}
                  key={index}
                >
                  {sim + ': ' + firstRel['SIMILARITY'][sim] + ', '}
                </span>
              ))}
            </label>
          </div>
        </div>
      </div>
    );
  };
  const handleSelectedRelationshipsModal = (relationships) => {
    return relationships.map((relationship, index) => {
      const rel = JSON.parse(props.relationshipsText[index]);
      return (
        <div className='aligner' key={index}>
          <form
            id='rel-edit'
            onSubmit={(ev) => props.onChange(ev, relationship, index)}
          >
            {areaLeft('GENERAL', rel['GENERAL'])}
            {areaLeft('SPECIFIC', rel['SPECIFIC'])}
            <div className='relationship-area-left'>
              <label className='relationship-sub-area-left'>
                CLASSIFICATION
              </label>
              <label className='relationship-sub-area-right'>
                {rel['CLASSIFICATION'].map((c, index) => (
                  <span key={index}>{c + ', '}</span>
                ))}
              </label>
            </div>
            {areaLeft('SOURCE DATASET', rel['SOURCE_DATASET'], true)}
            <div className='relationship-area-left'>
              <label className='relationship-sub-area-left'>SIMILARITY</label>
              <label className='relationship-sub-area-right'>
                {Object.keys(rel['SIMILARITY']).map((sim, index) => (
                  <span
                    className=''
                    style={{ display: 'inline-block' }}
                    key={index}
                  >
                    {sim + ': ' + rel['SIMILARITY'][sim] + ', '}
                  </span>
                ))}
              </label>
            </div>
            <div className='relationship-area-left'>
              <label className='relationship-sub-area-left'>SOURCE PDF</label>
              <a
                className='relationship-sub-area-right'
                href={rel['SOURCE_PDF']}
                target='_blank'
                rel='noreferrer'
              >
                {rel['SOURCE_PDF']}
              </a>
            </div>
            {areaLeft('LANGUAGE', rel['LANGUAGE'])}
            {areaLeft('VERSION', rel['VERSION'])}
            {areaLeft('TEXT LOCATION', rel['SOURCE_TEXT_LOCATION'])}
            <div className='relationship-area-left'>
              <label className='relationship-sub-area-left'>DETECTOR</label>
              <label
                className='relationship-sub-area-right'
                id='relationship-detector'
              >
                {rel['DETECTOR']}
              </label>
            </div>
            {areaLeft('SOURCE TEXT', rel['SOURCE_TEXT'])}
            <input
              style={{ visibility: 'hidden' }}
              type='submit'
              value='Change'
            />
          </form>
          <div className='input-group center'>
            <button onClick={() => props.onRemove(relationship, index)}>
              Remove
            </button>
          </div>
        </div>
      );
      // if (props.modal) return handleSelectedRelationshipsModal(rel, index);
    });
  };
  const areaLeft = (left, right, width) => {
    return (
      <div
        className='relationship-area-left'
        style={width ? { minWidth: '150px' } : {}}
      >
        <label className='relationship-sub-area-left'>{left}</label>
        <label className='relationship-sub-area-right'>{right}</label>
      </div>
    );
  };

  return (
    <div className='relationship-area'>
      <div className='relationship-group'>
        {!props.modal && handleSelectedRelationships(props.relationships)}
        {/* {props.relationships.map((rel, index) => { */}
        {props.modal && handleSelectedRelationshipsModal(props.relationships)}
        {/* })} */}
      </div>
    </div>
  );
}
