import { Component } from 'react';
import * as d3 from 'd3';

import './GraphD3.css';

class GraphD3 extends Component {
  //   constructor(props) {
  //     super(props);

  //     //this.node = null
  //     //this.link = null
  //     //this.circles = null
  //     //this.zoom = 100
  //   }

  draw(data, onClickLink, onClickNode, onClickBubble) {
    const links = data.links;
    const nodes = data.nodes;
    const circles = data.circles;

    // console.log('links');
    // console.log(links);
    // console.log('nodes');
    // console.log(nodes);
    // console.log('circles');
    // console.log(circles);

    //const width = 1000
    //const height = 960
    const width = 1200;
    const height = 800;
    const color = '#AAAAFF';
    const drag = (simulation) => {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
      }

      function dragged(event, d) {
        d3.event.subject.fx = d3.event.x + d3.event.dx;
        d3.event.subject.fy = d3.event.y + d3.event.dy;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d3.event.subject.fx = null;
        d3.event.subject.fy = null;
      }

      return d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended);
    };

    let force_value = 25;
    //        if(nodes.length > 100){
    //            force_value = nodes.length
    //        }

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(force_value)
      )
      .force('charge', d3.forceManyBody().strength(force_value * -2))
      //.force("charge", d3.forceManyBody().strength(-200))
      //.force("collide", d3.forceCollide().radius(20).strength(0.1))
      .force('x', d3.forceX())
      .force('y', d3.forceY());

    const bubble_simulation = d3
      .forceSimulation(circles.bubbles)
      .force(
        'link',
        d3
          .forceLink(circles.links)
          .id((d) => d.id)
          .distance(force_value)
      )
      .force('charge', d3.forceManyBody().strength(force_value * -20))
      //.force("charge", d3.forceManyBody().strength(-200))
      //.force("collide", d3.forceCollide().radius(20).strength(0.1))
      .force('x', d3.forceX())
      .force('y', d3.forceY());

    const svg = d3
      .create('svg')
      .attr('viewBox', [-width / 2, -height / 2, width, height]);
    //.call(d3.zoom().on("zoom", () => svg.attr("transform", d3.event.transform)));

    const svg_canvas = svg.append('g');

    let tooltip_div = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    tooltip_div.on('mouseout', function (d) {
      tooltip_div.transition().duration(500).style('opacity', 0);
    });

    const link = svg_canvas
      .append('g')
      .attr('stroke', '#2222FF')
      .attr('stroke-opacity', 0.2)
      .selectAll('line')
      .data(links)
      .join('line')
      .attr('stroke-width', (d) => 1) //add similarity to modify link size here, should be added in App.js to the link thing, then d.whatever_set_in_links
      .on('click', (d) => {
        onClickLink(d.source.id, d.target.id);
        d['selected'] = d.index;
        link.attr('stroke', (link) =>
          link.selected === d.index ? '#929' : '#22F'
        );
        //d => getLinkFor(d).attr("stroke", "#FF22FF")
      });

    const node = svg_canvas
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('g')
      .attr('stroke', '#FFF')
      .attr('stroke-width', 0.5);

    const circleNode = node
      .append('circle')
      .attr('r', 6)
      .attr('fill', color)
      .call(drag(simulation))
      .on('click', (d) => {
        onClickNode(d.id);
      });

    const tnode = node
      .append('text')
      .style('font', '5px times')
      .style('fill', 'black')
      .attr('stroke-width', 0)
      .attr('dx', (d) => (d.id.length / 2) * -2.5)
      .attr('dy', 10)
      .text((node) => node.id)
      .call(drag(simulation))
      .on('click', (d) => {
        onClickNode(d.id);
      })
      .raise();

    const bubbleLink = svg_canvas
      .append('g')
      .attr('stroke-opacity', 0.2)
      .selectAll('line')
      .data(circles.links)
      //.data(links)
      .join('line')
      .attr('stroke', (d) => (d.selected ? '#636' : '#33F'))
      .attr(
        'stroke-width',
        (d) => (d.count / 500 + Math.log2(d.count) * 20) / 50
      ) //add similarity to modify link size here, should be added in App.js to the link thing, then d.whatever_set_in_links
      .on('click', (d) => {
        //onClickLink(d.source.id, d.target.id)
      });

    const bnode = svg_canvas
      .selectAll('circle')
      .data(circles.bubbles)
      .enter()
      .append('g')
      .attr('stroke', '#FFF')
      .attr('stroke-width', 0.5);

    const bubbleNode = bnode
      .append('circle')
      .attr('r', (d) => (d.count / 500 + Math.log2(d.count) * 20) / 50)
      .attr('fill', color)
      .call(drag(bubble_simulation))
      .on('click', (d) => {
        tooltip_div.transition().duration(500).style('opacity', 0);
        onClickBubble(d.id);
      })
      .on('mouseover', function (d) {
        tooltip_div.transition().duration(200).style('opacity', 0.9);
        tooltip_div
          .html(d.mouseover_name.split(' (')[0] + '<br/>' + d.count)
          .style(
            'left',
            d3.event.pageX -
              (d.mouseover_name.split(' (')[0].length / 2) * -2.5 +
              'px'
          )
          .style('top', d3.event.pageY - 70 + 'px');
      })
      .on('mouseout', function (d) {
        tooltip_div.transition().duration(500).style('opacity', 0);
      });
    //.on("mouseover", (d) => {
    //    d["selected"] = d.index
    //    bubbleTextNode.text(bnode => bnode.selected == d.index ? bnode.mouseover_name.split(" (")[0] : bnode.id)
    //    bubbleTextNode.attr("dx", bnode => bnode.selected == d.index ? bnode.mouseover_name.split(" (")[0].length/2 * -2.5 : bnode.id.length/2 * -2.5)
    //})
    //.on("mouseout", (d) => {
    //    bubbleTextNode.text(bnode => bnode.id)
    //    bubbleTextNode.attr("dx", bnode => bnode.id.length/2 * -2.5)
    //})

    const bubbleTextNode = bnode
      .append('text')
      .style('font', '5px times')
      .style('fill', 'black')
      .attr('stroke-width', 0)
      .attr('dx', (d) => (d.id.length / 2) * -2.5)
      .attr('dy', 2.5)
      .text((bnode) => bnode.id)
      .call(drag(bubble_simulation))
      .on('click', (d) => {
        tooltip_div.transition().duration(500).style('opacity', 0);
        onClickBubble(d.id);
      })
      .on('mouseover', function (d) {
        tooltip_div.transition().duration(200).style('opacity', 0.9);
        tooltip_div
          .html(d.mouseover_name.split(' (')[0] + '<br/>' + d.count)
          .style(
            'left',
            d3.event.pageX -
              (d.mouseover_name.split(' (')[0].length / 2) * -2.5 +
              'px'
          )
          .style('top', d3.event.pageY - 70 + 'px');
      })

      //.on("mouseover", (d) => {
      //    d["selected"] = d.index
      //    bubbleTextNode.text(bnode => bnode.selected == d.index ? bnode.mouseover_name.split(" (")[0] : bnode.id)
      //    bubbleTextNode.attr("dx", bnode => bnode.selected == d.index ? bnode.mouseover_name.split(" (")[0].length/2 * -2.5 : bnode.id.length/2 * -2.5)
      //})
      //.on("mouseout", (d) => {
      //    bubbleTextNode.text(bnode => bnode.id)
      //    bubbleTextNode.attr("dx", bnode => bnode.id.length/2 * -2.5)
      //})
      .raise();

    // const bubbleText2Node = bnode.append('text')
    //     .style("font", "5px times")
    //     .style("fill", "black")
    //     .attr("stroke-width", 0)
    //     .attr("dx", d => Math.log(d.count)/4 * -2.5)
    //     .attr("dy", 7.5)
    //     .text(bnode => bnode.count)
    //     .call(drag(bubble_simulation))
    //     .on("click", (d) => {
    //         onClickBubble(d.id)
    //     })
    //     .on("mouseover", (d) => {
    //         d["selected"] = d.index
    //         bubbleTextNode.text(bnode => bnode.selected == d.index ? bnode.mouseover_name.split(" (")[0] : bnode.id)
    //         bubbleTextNode.attr("dx", bnode => bnode.selected == d.index ? bnode.mouseover_name.split(" (")[0].length/2 * -2.5 : bnode.id.length/2 * -2.5)
    //     })
    //     .on("mouseout", (d) => {
    //         bubbleTextNode.text(bnode => bnode.id)
    //         bubbleTextNode.attr("dx", bnode => bnode.id.length/2 * -2.5)
    //     })
    //     .raise()
    //This is debug code for rendering thresholds
    //svg.append('text')
    //    .style("font", "50px times")
    //    .style("gravity", "left")
    //    .style("fill", "white")
    //    .text(data.threshold)

    svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [width, height],
        ])
        .scaleExtent([-2, 8])
        .on('zoom', () => {
          svg_canvas.attr('transform', d3.event.transform);
          this.zoom = d3.event.transform;
        })
    );

    simulation.on('tick', () => {
      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);
      node.attr('cx', (d) => d.x).attr('cy', (d) => d.y);
      circleNode.attr('cx', (d) => d.x).attr('cy', (d) => d.y);
      tnode.attr('x', (d) => d.x).attr('y', (d) => d.y);
    });

    bubble_simulation.on('tick', () => {
      bubbleLink
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);
      bnode.attr('cx', (d) => d.x).attr('cy', (d) => d.y);
      bubbleNode.attr('cx', (d) => d.x).attr('cy', (d) => d.y);
      bubbleTextNode.attr('x', (d) => d.x).attr('y', (d) => d.y);
      // bubbleText2Node
      //     .attr("x", d => d.x)
      //     .attr("y", d => d.y);
    });

    //this.node = node
    //this.link = link
    //this.circles = circl

    const svg_node = svg.node();
    return svg_node;
  }
}

export default GraphD3;
