import React, { useEffect } from 'react';
import './modal.css';

export default function Modal(props) {
  useEffect(() => {
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [props]);

  if (!props.show) return null;

  return (
    <>
      <div className='modal-overlay' onClick={props.onClose}></div>
      <div className='modal'>
        <div className='modal-header'>
          <div className='modal-title'>{props.title}</div>
          <button className='modal-close' type='button' onClick={props.onClose}>
            &#10006;
          </button>
        </div>
        <div className='modal-body'>{props.children}</div>
        <div className='modal-footer'></div>
      </div>
    </>
  );
}
