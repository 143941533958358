import './App.css';

import React, { Component } from 'react';

import Modal from './components/Modal/modal';

import GraphD3 from './graph/GraphD3';

import logo from './imgs/logo.png';

import help_pdf from './res/AR-Graph-Passage-Search-Guidelines_20210714.pdf';
import Relationship from './components/Relationships/relationship';

// const TestData = require('./res/doQueryTestData.json');
// import { timeThursdays } from 'd3';

//import GraphRD3 from './graph/GraphRD3';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: new Set(),
      links: new Set(),
      duplicate_prevention_set: new Set(),
      relations: {},
      classification_checkbox_state: true,
      term_checkbox_state: true,
      wiki_checkbox_state: false,
      api_key: 'b10225791d2a478c9ff3d8cd106ad65a',
      term: 'metal',
      classification: 'A61K',
      //classification: "A01.236",
      count: 10,
      selected_relations: [],
      selected_relations_text: [],
      new_node_name: '',
      node1: '',
      node2: '',
      domain: 'patent',
      max_relations: 20,
      similarity_threshold: 0.5,
      show_help_popup: false,
      domain_similarity: { patent: ['PatBERT', 'SciBERT'], covid: ['SciBERT'] },
      selected_similarity: 'SciBERT',
      onto_classes_EPO: {},
      onto_classes_CORD: {},
      current_view: 0,
      current_view_selected: null,
      popout_selected_relationship: false,
    };

    this.state.onto_classes_EPO = require('./res/onto_classes_EPO.json');
    this.state.onto_classes_CORD = require('./res/onto_classes_CORD.json');

    this.classification_input_reference = React.createRef();
    this.term_input_reference = React.createRef();
    this.graph_reference = React.createRef();

    this.onClassificationCheckboxStateChange =
      this.onClassificationCheckboxStateChange.bind(this);
    this.onTermCheckboxStateChange = this.onTermCheckboxStateChange.bind(this);
    this.onWikiCheckboxStateChange = this.onWikiCheckboxStateChange.bind(this);
    this.onSubmitClicked = this.onSubmitClicked.bind(this);
    this.doQuery = this.doQuery.bind(this);
    this.onApiKeyTextChange = this.onApiKeyTextChange.bind(this);
    this.onTermTextChange = this.onTermTextChange.bind(this);
    this.onClassificationTextChange =
      this.onClassificationTextChange.bind(this);
    this.onClickLink = this.onClickLink.bind(this);
    this.onClickBubble = this.onClickBubble.bind(this);
    this.onDownloadClicked = this.onDownloadClicked.bind(this);
    this.onAddNodeClicked = this.onAddNodeClicked.bind(this);
    this.onClickNode = this.onClickNode.bind(this);
    this.onNewNodeNameTextChange = this.onNewNodeNameTextChange.bind(this);
    this.onAddLinkClicked = this.onAddLinkClicked.bind(this);
    //this.onChangeRelationClicked = this.onChangeRelationClicked.bind(this)
    this.onRemoveRelationClicked = this.onRemoveRelationClicked.bind(this);
    //this.onRelationTextChange = this.onRelationTextChange.bind(this)
    this.onQueryRelationClicked = this.onQueryRelationClicked.bind(this);
    this.onRemoveNodeClicked = this.onRemoveNodeClicked.bind(this);
    this.updateDownloadProgress = this.updateDownloadProgress.bind(this);
    this.onMaxRelationsChange = this.onMaxRelationsChange.bind(this);
    this.onSimilarityThresholdChange =
      this.onSimilarityThresholdChange.bind(this);

    this.graph = new GraphD3();
  }

  componentDidMount() {
    let search = window.location.search;
    if (search) {
      // console.log('search', search);
      const domain = this.getSearchValue(search, 'domain');
      const term = this.getSearchValue(search, 'term');
      this.setState(
        {
          domain: domain,
          term: term,
          max_relations: 2000,
          classification_checkbox_state: false,
          classification: '',
        },
        () => {
          this.doQuery(
            this.state.term_checkbox_state,
            this.state.classification_checkbox_state,
            this.state.domain,
            this.state.classification,
            this.state.term
          );
        }
      );
    } else {
      // console.log('no search');
    }
    const data = {
      nodes: Array.from(this.state.nodes),
      links: Array.from(this.state.links),
      circles: this.getCircleData(),
    };
    this.svg_graph = this.graph.draw(
      data,
      this.onClickLink,
      this.onClickNode,
      this.onClickBubble,
      this.graph_reference
    );
    document.getElementById('graph').appendChild(this.svg_graph);

    //alert("The Server is currently undergoing a planned maintenance. Expect outages and reduced results.")
  }

  getSearchValue(search, key) {
    const first = search.indexOf(key);
    const last =
      search.indexOf('&', first) === -1
        ? search.length
        : search.indexOf('&', first);
    const pair = search.substring(first, last);
    return pair.split('=')[1];
  }

  onSimilarityThresholdChange(event) {
    this.setState(
      {
        nodes: new Set(),
        links: new Set(),
        similarity_threshold: parseFloat(event.target.value),
      },
      () =>
        this.processNodes(
          Object.values(this.state.relations),
          this.state.selected_similarity
        )
    );
  }

  onMaxRelationsChange(event) {
    this.setState({ max_relations: parseInt(event.target.value) });
  }

  onApiKeyTextChange(event) {
    this.setState({ api_key: event.target.value });
  }

  onTermTextChange(event) {
    this.setState({ term: event.target.value });
  }

  onClassificationTextChange(event) {
    this.setState({ classification: event.target.value });
  }

  onClassificationCheckboxStateChange({ target }) {
    this.classification_input_reference.current.hidden =
      this.state.classification_checkbox_state;

    this.setState((prevState) => ({
      classification_checkbox_state: !prevState.classification_checkbox_state,
      classification: '',
    }));
  }

  onTermCheckboxStateChange({ target }) {
    this.term_input_reference.current.hidden = this.state.term_checkbox_state;

    this.setState((prevState) => ({
      term_checkbox_state: !prevState.term_checkbox_state,
      term: '',
    }));
  }

  onWikiCheckboxStateChange({ target }) {
    this.setState((prevState) => ({
      wiki_checkbox_state: !this.state.wiki_checkbox_state,
    }));
  }

  updateGraph() {
    const graph_data = {
      nodes: Array.from(this.state.nodes),
      links: Array.from(this.state.links),
      circles: this.getCircleData(),
      //threshold: this.state.similarity_threshold
    };

    // console.log(graph_data);
    const svg_graph = this.graph.draw(
      graph_data,
      this.onClickLink,
      this.onClickNode,
      this.onClickBubble,
      this.graph_reference
    );

    try {
      document.getElementById('graph').replaceChild(svg_graph, this.svg_graph);
    } catch (err) {}

    this.svg_graph = svg_graph;
  }

  onSubmitClicked(event) {
    event.preventDefault();
    //window.location.search = '';
    this.doQuery(
      this.state.term_checkbox_state,
      this.state.classification_checkbox_state,
      this.state.domain,
      this.state.classification,
      this.state.term
    );
  }

  doQuery(search_for_term, search_for_class, domain, classification, term) {
    if (this.state.api_key === 'demo' || this.state.api_key === '') {
      alert('Please enter a valid api key.');
      return;
    }

    const payload = JSON.stringify({
      api_key: this.state.api_key,
      return_type: 'json',
      window_size: this.state.max_relations,
      similarity_type: this.state.selected_similarity,
    });
    const data = new FormData();

    if (classification !== 'Unclassified') {
      classification = classification.toUpperCase();
    }
    term = term.toLowerCase();

    data.append('json', JSON.stringify(payload));

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: payload,
    };

    if (domain === 'covid') {
      domain = 'science';
    }

    let base_url = 'https://api.artificialresearcher.com/';
    // let base_url = 'http://localhost:5000/';

    let url = '';
    if (search_for_term) {
      if (search_for_class) {
        url = base_url + `ontology/v2/${domain}/${classification}/${term}`;
      } else {
        url = base_url + `ontology/v2/${domain}/all/${term}`;
      }
    } else {
      if (search_for_class) {
        url = base_url + `ontology/v2/${domain}/${classification}`;
      } else {
        alert("You can't search for nothing.");
        return;
      }
    }

    // console.log(url);

    this.setState({ is_loading: true });
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          selected_relations: [],
          nodes: new Set(),
          links: new Set(),
          duplicate_prevention_set: new Set(),
          relations: {},
          is_loading: false,
          current_view: 3,
        });
        this.processNodes(
          data['search_result'],
          this.state.selected_similarity
        );
      })
      .catch((error) => {
        // alert('There was an error. Please try again.');
        console.log(error);
        this.setState({ is_loading: false });
        // Used for dev when fetch fails
        // this.processNodes(
        //   TestData['search_result'],
        //   this.state.selected_similarity
        // );
      });
  }

  processNodes(relations, selected_similarity) {
    const nodes = new Set();
    const links = new Set();
    const duplicate_prevention_set = new Set();

    const all_relations = this.state.relations;

    for (let relation of relations) {
      const general = relation['GENERAL'];
      const specific = relation['SPECIFIC'];

      delete relation['METADATA'];

      all_relations[general + '_' + specific + '_' + relation['SOURCE_TEXT']] =
        relation;

      let duplicate_relation = false;
      if (
        relation['SIMILARITY'][selected_similarity] >=
        this.state.similarity_threshold
      ) {
        if (!duplicate_prevention_set.has(general)) {
          nodes.add({ id: general });
          duplicate_prevention_set.add(general);
          duplicate_relation |= true;
        }
        if (!duplicate_prevention_set.has(specific)) {
          nodes.add({ id: specific });
          duplicate_prevention_set.add(specific);
          duplicate_relation |= true;
        }
        //Allow duplicate links because we can have several duplicate relationships
        links.add({ source: general, target: specific });
        if (duplicate_relation) {
        }
      }
    }

    this.setState(
      {
        nodes: nodes,
        links: links,
        relations: all_relations,
      },
      () => this.updateGraph()
    );
  }

  onClickNode(nodeId) {
    this.setState({
      node1: this.state.node2,
      node2: nodeId,
    });
  }

  onClickLink(source, target) {
    const selected_relations = [];
    const selected_relations_text = [];
    for (let relation of Object.keys(this.state.relations)) {
      if (relation.startsWith(source + '_' + target + '_')) {
        selected_relations.push(relation);
        selected_relations_text.push(
          JSON.stringify(this.state.relations[relation], null, 2)
        );
      }
    }
    // console.log('selected_relations');
    // console.log(selected_relations);
    // console.log('selected_relations_text');
    // console.log(selected_relations_text);
    this.setState({
      selected_relations: selected_relations,
      selected_relations_text: selected_relations_text,
    });
  }

  onClickBubble(bubbleId) {
    let view_level = 0;
    if (bubbleId.length === 1) {
      view_level = 1;
    } else if (bubbleId.length === 3) {
      view_level = 2;
    } else if (
      bubbleId.length === 4 ||
      bubbleId.length === 7 ||
      bubbleId === 'Unclassified'
    ) {
      view_level = 3;
      this.doQuery(false, true, this.state.domain, bubbleId, '');
    }

    this.setState({
      current_view: view_level,
      current_view_selected: bubbleId,
    });

    // console.log('Current view selected:');
    // console.log(bubbleId);

    this.updateGraph();
  }

  onChangeRelationClicked(event, relation_id, index) {
    event.preventDefault();

    const relations = this.state.relations;
    delete relations[relation_id];

    const relation = JSON.parse(this.state.selected_relations_text[index]);

    const new_relation_id =
      relation['GENERAL'] +
      '_' +
      relation['SPECIFIC'] +
      '_' +
      relation['SOURCE_TEXT'];
    relations[new_relation_id] = relation;

    const selected_relations = this.state.selected_relations;
    selected_relations.splice(index, 1, new_relation_id);

    this.setState({
      relations: relations,
    });
  }

  onAddNodeClicked(event) {
    event.preventDefault();
    if (this.state.new_node_name) {
      this.setState({
        nodes: this.state.nodes.add({ id: this.state.new_node_name }),
        new_node_name: '',
      });
    } else {
      alert('Please type a name first');
    }

    this.updateGraph();
  }

  onNewNodeNameTextChange(event) {
    this.setState({ new_node_name: event.target.value });
  }

  onRelationTextChange(event, index) {
    event.preventDefault();
    const selected_relations_text = this.state.selected_relations_text; //this can probably be a one-liner but
    selected_relations_text[index] = event.target.value;
    this.setState({ selected_relations_text: selected_relations_text });
  }

  onRemoveRelationClicked(relation_id, index) {
    const relations = this.state.relations;
    const relation = this.state.relations[relation_id];
    delete relations[relation_id];
    const selected_relations = this.state.selected_relations;
    selected_relations.splice(index, 1);

    const links = Array.from(this.state.links);
    const link = { source: relation['GENERAL'], target: relation['SPECIFIC'] };
    for (let i = 0; i < links.length; i++) {
      if (
        links[i]['source']['id'] === link['source'] &&
        links[i]['target']['id'] === link['target']
      ) {
        links.splice(i, 1);
      }
    }
    // console.log(links);

    this.setState(
      {
        relations: relations,
        links: new Set(links),
        selected_relations: selected_relations,
      },
      this.updateGraph
    );
  }

  onQueryRelationClicked(event) {
    if (this.state.node2) {
      //let domain = document.getElementById("domains").value
      let domain = this.state.domain;

      const query_set = new Set();
      for (let rel of Object.values(this.state.relations)) {
        if (
          (this.state.node2 === rel['GENERAL'] ||
            this.state.node2 === rel['SPECIFIC']) &&
          rel['SIMILARITY'][this.state.selected_similarity] >=
            this.state.similarity_threshold
        ) {
          query_set.add(rel['GENERAL']).add(rel['SPECIFIC']);
        }
      }

      // console.log(query_set);

      let query_string = '';
      const single_words = [];
      const multi_term_words = [];
      for (let term of Array.from(query_set)) {
        let split_term = term.split('_');
        if (split_term.length > 1) {
          multi_term_words.push(
            '"' + split_term.join(' ') + '"~' + (split_term.length + 2)
          );
        } else {
          single_words.push(split_term);
        }
      }
      if (single_words.length > 0) {
        query_string += '(' + single_words[0];
        for (var i = 1; i < single_words.length; i++) {
          query_string += ' OR ' + single_words[i];
        }
        query_string += ')';
      }
      if (single_words.length > 0 && multi_term_words.length > 0) {
        query_string += ' OR ';
      }
      if (multi_term_words.length > 0) {
        query_string += '(' + multi_term_words[0];
        for (i = 1; i < multi_term_words.length; i++) {
          query_string += ' OR ' + multi_term_words[i];
        }
        query_string += ')';
      }

      // const api_key = this.state.api_key;
      const url =
        'https://alpha.passageretrieval.artificialresearcher.com/#/results?domain=' +
        domain +
        '&userDefined=true&query=' +
        query_string;
      // const url =
      //   'http://localhost:3000/#/results?domain=' +
      //   domain +
      //   '&userDefined=true&query=' +
      //   query_string;

      this.openUrl(url);
    }
  }

  openUrl(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  onAddLinkClicked(event) {
    if (this.state.node1 && this.state.node2) {
      const relations = this.state.relations;
      const relation = {
        GENERAL: this.state.node1,
        SPECIFIC: this.state.node2,
        CLASSIFICATION: ['User Defined'],
        SOURCE_TEXT: 'This relation was created at ' + Date.now(),
        SIMILARITY: {},
      }; //the time makes the text unique which prevents issues with the relations collection if someone were to create several relations between the same node without changing the text
      const relation_key =
        this.state.node1 +
        '_' +
        this.state.node2 +
        '_' +
        relation['SOURCE_TEXT'];
      relation['SIMILARITY'][this.state.selected_similarity] = 0.8; //it's not possible to initialize dictionaries with variables, so we'll have to do this as a second step https://stackoverflow.com/a/10640182/3565061
      relations[relation_key] = relation;

      const selected_relations = [];
      const selected_relations_text = [];

      selected_relations.push(relation_key);
      selected_relations_text.push(JSON.stringify(relation, null, 2));

      // console.log('selected_relations');
      // console.log(selected_relations);
      // console.log('selected_relations_text');
      // console.log(selected_relations_text);

      this.setState({
        links: this.state.links.add({
          source: this.state.node1,
          target: this.state.node2,
        }),
        relations: relations,
        selected_relations: selected_relations,
        selected_relations_text: selected_relations_text,
      });
      this.updateGraph();
    } else {
      alert('First click the general node, then the specific node');
    }
  }

  onRemoveNodeClicked(event) {
    const nodes = Array.from(this.state.nodes);
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i]['id'] === this.state.node2) {
        nodes.splice(i, 1);
      }
    }

    this.setState(
      {
        nodes: new Set(nodes),
        node2: '',
      },
      this.updateGraph
    );
  }

  async onDownloadClicked(event) {
    event.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const relations = this.state.relations;
    if (this.state.wiki_checkbox_state) {
      let elem = document.getElementById('progress');
      elem.style.width = 1 + '%';
      elem.innerHTML = 1 + '%';

      let limit = Object.keys(relations).length;
      if (limit > 5) {
        limit = 5;
      }
      for (let i = 0; i < limit; i++) {
        const relation = Object.values(relations)[i];
        const payload = {
          api_key: this.state.api_key,
          relation: {
            GENERAL: relation['GENERAL'],
            SPECIFIC: relation['SPECIFIC'],
            SOURCE_TEXT: relation['SOURCE_TEXT'],
          },
        };
        requestOptions['body'] = JSON.stringify(payload);
        const url = `https://api.artificialresearcher.com/extend/wiki`;
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        // console.log(data);
        relation['GENERAL_WIKI_DESCRIPTION'] = data['GENERAL_WIKI_DESCRIPTION'];
        relation['SPECIFIC_WIKI_DESCRIPTION'] =
          data['SPECIFIC_WIKI_DESCRIPTION'];
        relations[relation['GENERAL'] + '_' + relation['SPECIFIC']] = relation;
        this.updateDownloadProgress(i + 1, limit);
      }
    }

    const json = JSON.stringify(Object.values(relations), null, 4);

    const blob = new Blob([json], { type: 'application/json' });
    const download_element = document.createElement('a');
    const url = URL.createObjectURL(blob);
    download_element.download = 'ontology_graph.json';
    download_element.href = url;
    download_element.click();

    setTimeout(() => {
      let elem = document.getElementById('progress');
      elem.style.width = 0 + '%';
      elem.innerHTML = 0 + '%';
    }, 5000);
  }

  updateDownloadProgress(current, total) {
    let elem = document.getElementById('progress');
    let width = (current / total) * 100;
    elem.style.width = width + '%';
    elem.innerHTML = width + '%';
  }

  getCircleData() {
    if (this.state.domain === 'patent') {
      return this.getEPOCircleData();
    } else {
      return this.getCORDCircleData();
    }
  }

  getCORDCircleData() {
    let selected = null;
    // console.log('current state');
    // console.log(this.state.current_view);
    let bubbles = [];
    let links = [];
    switch (this.state.current_view) {
      case 0: // MeSH class display A,B,C...
        for (let c in this.state.onto_classes_CORD) {
          bubbles.push({
            id: c,
            count: this.state.onto_classes_CORD[c]['word_relationship_count'],
            mouseover_name: this.state.onto_classes_CORD[c]['description'],
          });

          for (let cr in this.state.onto_classes_CORD[c][
            'class_relationship_count'
          ]) {
            links.push({
              source: c,
              target: cr,
              count:
                this.state.onto_classes_CORD[c]['class_relationship_count'][cr],
            });
          }
        }

        return { bubbles: bubbles, links: links };
      case 1: // MeSH class display A01, A02, B01
        selected =
          this.state.onto_classes_CORD[this.state.current_view_selected];
        for (let c in selected) {
          if (c.length === 3 && selected[c]['word_relationship_count'] > 0) {
            bubbles.push({
              id: c,
              count: selected[c]['word_relationship_count'],
              mouseover_name: selected[c]['description'],
            });
            for (let cr in selected[c]['class_relationship_count']) {
              links.push({
                source: c,
                target: cr,
                count: selected[c]['class_relationship_count'][cr],
              });
            }
          }
        }
        return { bubbles: bubbles, links: links };
      case 2: // MeSH class display A01.236,...
        selected =
          this.state.onto_classes_CORD[this.state.current_view_selected[0]][
            this.state.current_view_selected
          ];
        for (let c in selected) {
          if (c.length === 7 && selected[c]['word_relationship_count'] > 0) {
            bubbles.push({
              id: c,
              count: selected[c]['word_relationship_count'],
              mouseover_name: selected[c]['description'],
            });
            for (let cr in selected[c]['class_relationship_count']) {
              links.push({
                source: c,
                target: cr,
                count: selected[c]['class_relationship_count'][cr],
              });
            }
          }
        }
        return { bubbles: bubbles, links: links };
      default: //word relationship display.
        return { bubbles: [], links: [] };
    }
  }

  getEPOCircleData() {
    let selected = null;
    let bubbles = [];
    let links = [];
    switch (this.state.current_view) {
      case 0: // ipc class display A,B,C
        for (let c in this.state.onto_classes_EPO) {
          bubbles.push({
            id: c,
            count: this.state.onto_classes_EPO[c]['word_relationship_count'],
            mouseover_name: this.state.onto_classes_EPO[c]['description'],
          });
          for (let cr in this.state.onto_classes_EPO[c][
            'class_relationship_count'
          ]) {
            links.push({
              source: c,
              target: cr,
              count:
                this.state.onto_classes_EPO[c]['class_relationship_count'][cr],
            });
          }
        }

        return { bubbles: bubbles, links: links };
      case 1: // ipc class display A61, A62, A63
        selected =
          this.state.onto_classes_EPO[this.state.current_view_selected];
        for (let c in selected) {
          if (c.length === 3 && selected[c]['word_relationship_count'] > 0) {
            bubbles.push({
              id: c,
              count: selected[c]['word_relationship_count'],
              mouseover_name: selected[c]['description'],
            });
            for (let cr in selected[c]['class_relationship_count']) {
              links.push({
                source: c,
                target: cr,
                count: selected[c]['class_relationship_count'][cr],
              });
            }
          }
        }
        return { bubbles: bubbles, links: links };
      case 2: // ipc class display A61K, A61I, A61J
        selected =
          this.state.onto_classes_EPO[this.state.current_view_selected[0]][
            this.state.current_view_selected
          ];
        for (let c in selected) {
          if (c.length === 4 && selected[c]['word_relationship_count'] > 0) {
            bubbles.push({
              id: c,
              count: selected[c]['word_relationship_count'],
              mouseover_name: selected[c]['description'],
            });
            for (let cr in selected[c]['class_relationship_count']) {
              links.push({
                source: c,
                target: cr,
                count: selected[c]['class_relationship_count'][cr],
              });
            }
          }
        }
        return { bubbles: bubbles, links: links };
      default: //word relationship display.
        return { bubbles: [], links: [] };
    }
  }

  clear_graph() {
    this.setState(
      {
        current_view: 0,
        current_view_selected: null,
        selected_relations: [],
        selected_relations_text: [],
        nodes: new Set(),
        links: new Set(),
        node1: '',
        node2: '',
      },
      this.updateGraph()
    );
  }

  render() {
    // the graph configuration, just override the ones you need
    return (
      <div className='App' id='app'>
        <div className='help'>
          <div
            className='help-icon'
            onClick={() => this.setState({ show_help_popup: true })}
          >
            ?
          </div>
          <div
            className='help-icon'
            onClick={() =>
              this.openUrl(
                'https://forms.office.com/pages/responsepage.aspx?id=Bm5ROwIhzUCIvIv1b3-AFfKFZnnGIZ5JiFjqFUZ5wUFURVJGQk5IRkFSRDBZMlBWQ1VVWTdKOEtLTS4u'
              )
            }
          >
            !
          </div>
        </div>
        <div className='Graph-Field' id='graph' ref={this.graph_reference}>
          {this.state.is_loading && (
            <div className='loader' ref={this.graph_reference}>
              {' '}
            </div>
          )}
          <img className='logo' src={logo} alt='logo' />
        </div>
        <div className='sidebar'>
          <form onSubmit={this.onSubmitClicked}>
            <div className='input-group'>
              <label className='leftlabel' htmlFor='api_key'>
                Api Key:
              </label>
              <div>
                <input
                  type='text'
                  id='api_key'
                  name='api_key'
                  placeholder='Your api Key'
                  value={this.state.api_key}
                  onChange={this.onApiKeyTextChange}
                />
              </div>
            </div>
            <div className='input-group'>
              <label className='leftlabel' htmlFor='domains'>
                Domain:{' '}
              </label>
              <div>
                <select
                  className='leftlabel'
                  name='domains'
                  id='domains'
                  value={this.state.domain}
                  onChange={(e) =>
                    this.setState(
                      {
                        domain: e.target.value,
                        selected_similarity:
                          this.state.domain_similarity[e.target.value][0],
                      },
                      () => {
                        this.clear_graph();
                      }
                    )
                  }
                >
                  <option value='patent'>Patent</option>
                  <option value='covid'>Covid</option>
                </select>
              </div>
            </div>
            <div className='input-group'>
              <input
                type='checkbox'
                id='term-cb'
                name='term-cb'
                onChange={this.onTermCheckboxStateChange}
                defaultChecked={this.state.term_checkbox_state}
              />
              <label className='leftlabel' htmlFor='term'>
                Term:
              </label>
              <div>
                <input
                  type='text'
                  id='term'
                  name='term'
                  ref={this.term_input_reference}
                  placeholder='disease'
                  value={this.state.term}
                  onChange={this.onTermTextChange}
                />{' '}
              </div>
            </div>
            <div className='input-group'>
              <input
                type='checkbox'
                id='classification-cb'
                name='classification-cb'
                onChange={this.onClassificationCheckboxStateChange}
                // defaultChecked={this.state.classification_checkbox_state}
                checked={this.state.classification_checkbox_state}
              />
              <label className='leftlabel' htmlFor='classification'>
                Classification:
              </label>
              <div>
                <input
                  type='text'
                  id='classification'
                  name='classification'
                  ref={this.classification_input_reference}
                  placeholder='A01.236'
                  value={this.state.classification}
                  onChange={this.onClassificationTextChange}
                />
              </div>
            </div>
            <div className='input-group'>
              <label className='leftlabel' htmlFor='max_relations'>
                Max Relations:
              </label>{' '}
              <label htmlFor='max_relations'> {this.state.max_relations}</label>
              <div>
                <input
                  type='range'
                  id='max_relations'
                  name='max_relations'
                  min='5'
                  max='2000'
                  value={this.state.max_relations}
                  onChange={this.onMaxRelationsChange}
                />
              </div>
            </div>
            <div className='input-group'>
              <select
                className='leftlabel'
                name='similarities'
                onChange={(e) =>
                  this.setState({ selected_similarity: e.target.value })
                }
              >
                {this.state.domain_similarity[this.state.domain].map(
                  (val, index) => (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className='input-group'>
              <label className='leftlabel' htmlFor='similarity_threshold'>
                Similarity Threshold:
              </label>{' '}
              <label className='leftlabel' htmlFor='similarity_threshold'>
                {' '}
                {this.state.similarity_threshold}
              </label>
              <div>
                <input
                  type='range'
                  id='similarity_threshold'
                  name='similarity_threshold'
                  min='.5'
                  max='.99'
                  step='0.01'
                  value={this.state.similarity_threshold}
                  onChange={this.onSimilarityThresholdChange}
                />
              </div>
            </div>
            <div className='actions'>
              <input type='submit' value='Submit' />
            </div>
          </form>

          <hr />

          {this.state.selected_relations.length > 0 && (
            <Relationship
              relationships={this.state.selected_relations}
              relationshipsText={this.state.selected_relations_text}
              onChange={this.onChangeRelationClicked}
              onRemove={this.onRemoveRelationClicked}
              onApiChange={this.onApiKeyTextChange}
              modal={false}
            />
          )}

          {this.state.selected_relations.length > 0 && (
            <>
              <div className='input-group center'>
                <button
                  className=''
                  onClick={() =>
                    this.setState({ popout_selected_relationship: true })
                  }
                >
                  More information
                </button>
              </div>

              <hr />
            </>
          )}

          <div className='center'>
            <form id='add-node' onSubmit={this.onAddNodeClicked}>
              <div className='input-group'>
                <label className='leftlabel' htmlFor='new_node_name'>
                  New Node Name:
                </label>
                <div>
                  <input
                    type='text'
                    id='new_node_name'
                    name='new_node_name'
                    placeholder='new'
                    value={this.state.new_node_name}
                    onChange={this.onNewNodeNameTextChange}
                  />
                  <input type='submit' value='Add Node' />
                </div>
              </div>
            </form>
            <label>
              <pre>Currently selected Node:</pre>
            </label>
            <label>
              <pre>{this.state.node2}</pre>
            </label>
            <button onClick={this.onQueryRelationClicked}>Query Node</button>
            &nbsp;
            <button onClick={this.onRemoveNodeClicked}>Remove Node</button>
            <br />
            <br />
            <label>
              <pre>Link Nodes: </pre>
            </label>
            <label>
              <pre>General - Specific</pre>
            </label>
            <label>
              <pre>
                {this.state.node1} - {this.state.node2}
              </pre>
            </label>
            <button onClick={this.onAddLinkClicked}>Create Link</button>
            <br />
            <br />
            <form id='rel-edit' onSubmit={this.onDownloadClicked}>
              <input type='submit' value='Download' />
              <div>
                <input
                  type='checkbox'
                  id='wiki-cb'
                  name='wiki-cb'
                  defaultChecked={this.state.wiki_checkbox_state}
                  onChange={this.onWikiCheckboxStateChange}
                />
                <label htmlFor='checkbox'>with Wiki (slow, max 5)</label>
              </div>
            </form>
          </div>
          <div id='progressBar'>
            <div id='progress'>0%</div>
          </div>
        </div>

        {this.state.show_help_popup && (
          <Modal
            title={'How to use!'}
            show={this.state.show_help_popup}
            onClose={() => this.setState({ show_help_popup: false })}
          >
            <iframe
              src={help_pdf}
              width='100%'
              height='100%'
              title='Help'
              style={{ border: 'none' }}
            ></iframe>
          </Modal>
        )}

        {this.state.popout_selected_relationship && (
          <Modal
            title={''}
            show={this.state.popout_selected_relationship}
            onClose={() =>
              this.setState({ popout_selected_relationship: false })
            }
          >
            <Relationship
              relationships={this.state.selected_relations}
              relationshipsText={this.state.selected_relations_text}
              onChange={this.onChangeRelationClicked}
              onRemove={this.onRemoveRelationClicked}
              modal={true}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default App;
